import React, { useState } from 'react';
import styled from 'styled-components';
import Webcam from "react-webcam";

import plane from '../images/plane.png'
import barcode from '../images/barcode.png'
import uploadbg from '../images/upload-bg.jpg'
import Button from './button'
import Screenshot from './screenshot'

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 3.5rem;
`

const Field = styled.label`
  background: ${props => props.checked ? "white" : "transparent"};
  border: ${props => props.checked ? "2px" : "1px"} solid black;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 1rem;
  cursor: pointer;
  font-size: 0.9rem;
`

const SizeOutline = styled.div`
  width: ${props => props.vertical ? '45px' : '80px'};
  height: ${props => props.vertical ? '80px' : '80px'};
  border: 1px #7c7c7c dashed;
  margin: 0 auto 1rem;
  &:after {
    content: "${props => props.vertical ? "9:16" : "1:1"}";
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`

const ColorBox = styled.div`
  background-color: ${props => props.color};
  width: 75px;
  height: 75px;
  margin: 0 auto 1rem;
`

const PassWrap = styled.div`
  border: 15px black solid;
  border-radius: 10px;
  background-color: ${props => props.color};
  text-align: left;
  padding: 0;
  box-shadow: 5px 6px 20px 6px rgb(0 0 0 / 40%);
  h2 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.5rem;
    padding: 1rem;
    margin: 0;
    background: rgba(0,0,0,0.25);
  }
`

const PassInner = styled.div`
  padding: 1rem;
  h3 {
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0;
    img {
      width: 1em;
      margin: 0 0.5em;
      transform: translateY(0.1em);
    }
  }
  hr {
    border: 1px black solid;
    margin: 1rem auto;
  }
  h4 {
    margin: 0 auto 0.5rem;
  }
  input[type="text"] {
    border: 3px black solid;
    border-radius: 5px;
    width: calc(100% - 1rem);
    padding: 0.5rem;
  }
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .type {
      font-weight: 800;
    }
  }
`

const CameraWrap = styled.div`
  border: 3px solid black;
  width: 100%;
  margin-top: 1rem;
  video {
    width: 100%;
    margin-bottom: -5px;
  }
  img {
    width: 100%;
  }
  .preview {
    position: relative;
    button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
`

const Barcode = styled.div`
  margin: 1em auto;
  img {
    width: 100%;
  }
`

const PreviewWrap = styled.div`
  img {
    width: 100%;
  }
`

const PassCreator = (props) => {
	const [size, setSize] = useState(0);
	const [color, setColor] = useState(0);
  const [colorName, setColorName] = useState(0);
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState(0);
  const [camPermission, setCamPermission] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const screenshot = webcamRef.current.getScreenshot();
    setPhoto(screenshot);
  }, [webcamRef, setPhoto]);

  const handleColorChange = e => {
    setColor(e.target.value)
    setColorName(e.target.getAttribute("color-name"))
  }

  const handleUserMedia = () => {
    if (!camPermission) {
      setCamPermission(true)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    document.querySelectorAll('.container')[0].classList.add('__wide')
  }

  const startUpload = () => {
    document.getElementById('uploadField').click();
  }

  const handleUpload = e => {
    setPhoto(URL.createObjectURL(e.target.files[0]))
  }

  return (
    <div className="pass-creator">
      <div className={submitted ? 'hide' : 'show'}>
        <h2 className="title">Please select a size</h2>
        <FieldGroup>
          <Field 
            checked={size === "vertical"}
            className="__vertical"
          >
            <SizeOutline vertical="true" />
            <input 
              type="radio" 
              name="size" 
              value="vertical" 
              id="size_vertical" 
              checked={size === "vertical"}
              onChange={e => setSize(e.target.value)}
            />
            Vertical
          </Field>
          <Field 
            checked={size === "square"}
            className="__square"
          >
            <SizeOutline />
            <input 
              type="radio" 
              name="size" 
              value="square" 
              id="size_square" 
              checked={size === "square"}
              onChange={e => setSize(e.target.value)}
            />
            Square
          </Field>
        </FieldGroup>

        <h2 className="title">Please select a color</h2>
        <FieldGroup>
          <Field 
            checked={color === "#efb781"}
          >
            <ColorBox color="#efb781" />
            <input 
              type="radio" 
              name="color" 
              value="#efb781" 
              id="color_orange" 
              checked={color === "#efb781"}
              color-name="orange" 
              onChange={e => handleColorChange(e)}
            />
            Orange
          </Field>
          <Field 
            checked={color === "#83f1bc"}
          >
            <ColorBox color="#83f1bc" />
            <input 
              type="radio" 
              name="color" 
              value="#83f1bc" 
              id="color_green" 
              checked={color === "#83f1bc"}
              color-name="green" 
              onChange={e => handleColorChange(e)}
            />
            Green
          </Field>
          <Field 
            checked={color === "#eef182"}
          >
            <ColorBox color="#eef182" />
            <input 
              type="radio" 
              name="color" 
              value="#eef182" 
              id="color_yellow" 
              checked={color === "#eef182"}
              color-name="yellow" 
              onChange={e => handleColorChange(e)}
            />
            Yellow
          </Field>
        </FieldGroup>

        <h2 className="title">Pass preview</h2>
        <PassWrap
          color={color}
        >
          <h2>Weekend Airways</h2>
          <PassInner>
            <h3>
              <span>FRI</span>
              <span><img src={plane} alt="aeroplane icon" /></span>
              <span>SUN</span>
            </h3>
            <hr />
            <h4>Passenger:</h4>
            <input 
              type="text" 
              name="name" 
              id="name" 
              placeholder="Your name" 
              value={name} 
              onChange={e => setName(e.target.value)}
            />
            <hr />
            <div className="info">
              <div>
                <div className="type">Flight:</div>
                <div className="detail">27</div>
              </div>
              <div>
                <div className="type">Gate:</div>
                <div className="detail">08</div>
              </div>
              <div>
                <div className="type">Departs:</div>
                <div className="detail">20:21</div>
              </div>
            </div>
            <div className="photo">
              <CameraWrap>
                {
                  photo ? 
                    <div className="preview">
                      <img src={photo} alt="screenshot" id="snapshot_image" />
                      <Button 
                        type="button" 
                        text={camPermission ? 'Take again ›' : 'Choose another ›'}
                        onClick={e => setPhoto(0)} 
                      />
                    </div>
                  :
                    <div 
                      onClick={camPermission ? null : startUpload} 
                      className={camPermission ? 'video-window' : 'upload-window'} 
                    >
                      <Webcam
                        ref={webcamRef}
                        mirrored="true" 
                        screenshotFormat="image/jpeg"
                        onClick={capture} 
                        onUserMedia={handleUserMedia}
                        id="videoWindow"
                      />
                      <input 
                        type="file" 
                        id="uploadField"
                        className="hide" 
                        onChange={e => handleUpload(e)}
                      />
                      <img src={uploadbg} alt="Upload image" />
                    </div>
                }
              </CameraWrap>
            </div>
            <Barcode>
              <img src={barcode} alt="barcode" />
            </Barcode>
          </PassInner>
        </PassWrap>
        {
          photo ?
            <Button 
              text="Get my WEEKEND pass ›" 
              type="button"
              onClick={handleSubmit}
            />
          :
            <Button 
              text={camPermission ? "Capture photo ›" : "Upload photo ›"} 
              type="button"
              onClick={camPermission ? capture : startUpload}
            />
        }
      </div>
      <PreviewWrap>
        {
          size && color && colorName && name && photo && submitted ?
            <div className={submitted ? 'show' : 'hide'}>
              <Screenshot 
                size={size}
                color={color}
                colorName={colorName}
                name={name}
                photo={photo}
              />
            </div>
          : ''
        }
      </PreviewWrap>
    </div>
  )

};

export default PassCreator;
