import * as React from "react"
import Helmet from 'react-helmet'
import { withPrefix } from "gatsby-link"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Background from '../components/background'
import PassCreator from '../components/passCreator'
import Footer from '../components/footer'
import bg2Top from '../images/bg2-top.png'
import bg2Bottom from '../images/bg2-bottom.png'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <script src={withPrefix('canvas-plus.js')} type="text/javascript" />
    </Helmet>
    <Seo title="Home" />
    <Background 
      bgcolor="#e3b3f5"
      size="cover" 
      position="center center"
      repeat="no-repeat"
      height="100vh"
      location="top"
    />
    <Background 
      image={bg2Top} 
      size="contain" 
      position="center top"
      repeat="no-repeat"
      height="100vh"
      location="top"
    />
    <Background 
      image={bg2Bottom} 
      size="contain" 
      position="center bottom"
      repeat="no-repeat"
      height="100vh"
      location="bottom"
    />
    <div className="container">
      <PassCreator />
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
