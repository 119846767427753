import React, { useState } from 'react';
import { Link } from "gatsby";
import { saveAs } from 'file-saver';
import styled from 'styled-components'

import Button from './button'
import templateGreenSquare from '../images/template-green-square.png'
import templateGreenVertical from '../images/template-green-vertical.png'
import templateOrangeSquare from '../images/template-orange-square.png'
import templateOrangeVertical from '../images/template-orange-vertical.png'
import templateYellowSquare from '../images/template-yellow-square.png'
import templateYellowVertical from '../images/template-yellow-vertical.png'

const ShareSection = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  .column {
    width: calc(50% - 2rem);
    padding: 1rem;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    .column {
      width: calc(100% - 2rem);
    }
  }
`

const Screenshot = (props) => {
  const [composite, setComposite] = useState(0);
  const [needsRendered, setNeedsRendered] = useState(true);

  const images = {
    green: {
      square: templateGreenSquare,
      vertical: templateGreenVertical
    },
    orange: {
      square: templateOrangeSquare,
      vertical: templateOrangeVertical
    },
    yellow: {
      square: templateYellowSquare,
      vertical: templateYellowVertical
    }
  }
  
  const getPercentIncrease = (a, b) => {
    let percent;
    if(b !== 0) {
        if(a !== 0) {
            percent = (b - a) / a * 100;
        } else {
            percent = b * 100;
        }
    } else {
        percent = - a * 100;            
    }       
    return Math.floor(percent);
  }

  const getDimensions = image => {
    const srcWidth = image.offsetWidth
    const srcHeight = image.offsetHeight
    let marginX, marginY, width, height
    if (props.size === "square") {
      // Image is square
      marginY = 471
      marginX = 294
      if (srcHeight > srcWidth) {
        // Photo is portrait
        height = 465
        const percIncrease = getPercentIncrease(srcHeight, height)
        const widthIncrease = (srcWidth/100) * percIncrease
        width = srcWidth + widthIncrease
        marginX = 1080 - (width * 1.5)
      } else {
        // Photo is landscape
        width = 494
        const percIncrease = getPercentIncrease(srcWidth, width)
        const heightIncrease = (srcHeight/100) * percIncrease
        height = srcHeight + heightIncrease
        marginY = 1080 - (height * 1.5)
      }
    } else {
      // Image is portrait
      marginY = 803
      marginX = 115
      if (srcHeight > srcWidth) {
        // Photo is portrait
        height = 784
        const percIncrease = getPercentIncrease(srcHeight, height)
        const widthIncrease = (srcWidth/100) * percIncrease
        width = srcWidth + widthIncrease
        marginX = 1080 - (width * 1.5)
      } else {
        // Photo is landscape
        width = 850
        const percIncrease = getPercentIncrease(srcWidth, width)
        const heightIncrease = (srcHeight/100) * percIncrease
        height = srcHeight + heightIncrease
        marginY = 1850 - (height * 1.5)
      }
    }
    return {marginX, marginY, width, height}
  }

  const canvas = typeof window === "undefined" ? false : new window.CanvasPlus();

  needsRendered && canvas.load(images[props.colorName][props.size], function(err) {
    if (err) throw err;

    // Hide image, but preserve dimensions for calculation
    const image = document.getElementById('snapshot_image')
    image.style.position = "absolute"
    image.style.bottom = "-10000px"
    image.style.left = "-10000px"
    document.body.appendChild(image)

    // Composite photo image
    const dimensions = getDimensions(image)
    canvas.composite({
      "image": document.getElementById('snapshot_image'),
      "gravity": "northwest",
      ...dimensions
    });

    // Composite the text
    canvas.text({
      "text": props.name,
      "font": 'Open Sans',
      "fontWeight": 300,
      "fontStyle": "normal",
      "size": 40,
      "color": "#000000",
      "gravity": "northwest",
      "marginX": props.size === "square" ? 294 : 120,
      "marginY": props.size === "square" ? 300 : 547,
    })

    // Output
    canvas.write({"format":"jpeg","quality":90}, function(err, buf) {
      if (err) throw err;
      const blob = new Blob( [ buf ], { type: "image/jpeg" } );
      const object_url = URL.createObjectURL( blob );
      setComposite(object_url)
      setNeedsRendered(false)
    });

  });

  const startDownload = () => {
    saveAs(composite, `${Date.now()}_weekendPass.jpg`);
  }

  return (
    composite ? 
      <ShareSection>
        <div className="column">
          <Link to="/">‹ Start Over</Link>
          <h1 className="title">Share your boarding pass</h1>
          <img src={composite} alt="" id="renderedComposite" />
          <Button 
            type="button"
            text="↓ Download your pass" 
            fullwidth={true}
            onClick={startDownload}
          />
        </div>
        <div className="column">
        <h1 className="title">Listen to the playlist</h1>
          <iframe 
            src="https://open.spotify.com/embed/playlist/14zMQIcAJev3tX7GOzz6fy" 
            width="100%" 
            height="380" 
            frameBorder="0" 
            allowtransparency="true" 
            allow="encrypted-media"
            title="playlistEmbed"
          />
        </div>
      </ShareSection>
    : ''
  )


}

export default Screenshot